import { numberWithDelimiter } from "../utils/numberWithDelimiter";

export default function BigNumber({
  amount,
  color,
  label,
  isLast,
}: {
  amount: number;
  color: string;
  label: string;
  isLast?: boolean;
}) {
  return (
    <div>
      <h2 className="font-bold text-xl text-center mb-3">{label}</h2>
      <h3
        className={`font-bold text-6xl ${
          !isLast && "mb-12"
        } text-center ${color}`}
      >
        {numberWithDelimiter(amount)}
      </h3>
    </div>
  );
}
