import { useState } from "react";

export default function Input({
  defaultValue,
  label,
  onChange,
  isLast,
}: {
  defaultValue: number;
  label: string;
  onChange: (integer: number) => void;
  isLast?: boolean;
}) {
  const [isText, setIsText] = useState(false);
  const [value, setValue] = useState(`${defaultValue}`);

  return (
    <div className={`${!isLast && "mb-6"}`}>
      <p className="font-bold">{label}</p>
      <input
        className="w-full sm:max-w-xs border-2 border-solid border-gray-500 focus:border-orange-500 focus:outline-none rounded-lg py-1.5 px-2"
        type="text"
        value={value}
        onChange={(event) => {
          const inputString = event.target.value;
          if (/^\d+$/.test(inputString) || inputString.length === 0) {
            onChange(parseInt(inputString));
            setValue(inputString);
            if (isText) setIsText(false);
          } else {
            setIsText(true);
          }
        }}
        style={{
          backgroundColor: "#191919",
        }}
      />
      {(isText || value.length === 0) && (
        <p className="font-bold text-red-500">
          {isText ? "Kun heltall" : "Må fylles ut"}
        </p>
      )}
    </div>
  );
}
