import { useEffect, useState } from "react";
import "./App.css";
import BigNumber from "./components/BigNumber";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NumberField from "./components/NumberField";

function App() {
  const BASEAMOUNT = 111477;
  // Salary
  const [salary, setSalary] = useState(0);
  const [overtimePercent, setOvertimePercent] = useState(140);
  const [overtimeHours, setOvertimeHours] = useState(0);
  const [holidayDays, setHolidayDays] = useState(25);
  const [bonus, setBonus] = useState(0);
  const [totalSalary, setTotalSalary] = useState(0);

  // Pension
  const [pensionLowerBracketPercent, setPensionLowerBracketPercent] =
    useState(4);
  const [pensionUpperBracketPercent, setPensionUpperBracketPercent] =
    useState(6);
  const [pensionNav, setPensionNav] = useState(0);
  const [totalPension, setTotalPension] = useState(0);

  // Total
  const [totalIncome, setTotalIncome] = useState(0);

  // Salary
  useEffect(() => {
    const hourlyRate = salary / 1950;
    const overtime = hourlyRate * (overtimePercent / 100) * overtimeHours;
    const holidayDeduction = hourlyRate * 7.5 * holidayDays;
    const holidayPay = (salary + overtime + bonus - holidayDeduction) * 0.12;
    setTotalSalary(
      Math.round(salary + overtime + bonus - holidayDeduction + holidayPay)
    );
  }, [salary, overtimePercent, overtimeHours, holidayDays, bonus]);

  // Pension - NAV
  useEffect(() => {
    if (totalSalary >= BASEAMOUNT * 7.1) {
      setPensionNav(Math.round(BASEAMOUNT * 7.1 * 0.181));
    } else {
      setPensionNav(Math.round(totalSalary * 0.181));
    }
  }, [totalSalary]);

  // Pension
  useEffect(() => {
    const pensionLowerBracketMultiplier = pensionLowerBracketPercent / 100;
    const pensionHigherBracketMultiplier = pensionUpperBracketPercent / 100;
    let pensionAmountLowerBracket = 0;
    let pensionAmountHigherBracket = 0;
    if (salary > BASEAMOUNT * 7.1) {
      pensionAmountLowerBracket =
        BASEAMOUNT * 7.1 * pensionLowerBracketMultiplier;
      if (salary > BASEAMOUNT * 12) {
        pensionAmountHigherBracket =
          BASEAMOUNT * (12 - 7.1) * pensionHigherBracketMultiplier;
      } else {
        pensionAmountHigherBracket =
          (salary - BASEAMOUNT * 7.1) * pensionHigherBracketMultiplier;
      }
    } else {
      pensionAmountLowerBracket = salary * pensionLowerBracketMultiplier;
    }
    setTotalPension(
      Math.round(
        pensionAmountLowerBracket + pensionAmountHigherBracket + pensionNav
      )
    );
  }, [pensionLowerBracketPercent, pensionUpperBracketPercent, pensionNav]);

  // Total
  useEffect(() => {
    setTotalIncome(totalSalary + totalPension);
  }, [totalSalary, totalPension]);

  return (
    <div
      id="App"
      className="flex flex-col container max-w-3xl min-h-screen mx-auto text-gray-200"
    >
      <Header />
      <main className="w-full px-6">
        <h2 className="py-24 font-bold text-3xl text-center">
          Inntektskalkulator
        </h2>
        <div className="sm:flex sm:flex-row">
          <div className="sm:flex-1">
            <NumberField
              label="Årslønn"
              defaultValue={salary}
              onChange={(value: number) => setSalary(value)}
            />
            <NumberField
              label="Feriedager"
              defaultValue={holidayDays}
              onChange={(value: number) => setHolidayDays(value)}
            />
            <NumberField
              label="Pensjon 0 -> 7.1G (%)"
              defaultValue={pensionLowerBracketPercent}
              onChange={(value: number) => setPensionLowerBracketPercent(value)}
            />
            {salary > BASEAMOUNT * 7.1 && (
              <NumberField
                label="Pensjon 7.1 -> 12G (%)"
                defaultValue={pensionUpperBracketPercent}
                onChange={(value: number) =>
                  setPensionUpperBracketPercent(value)
                }
              />
            )}
            <NumberField
              label="Overtidsrate, minimum 140 (%)"
              defaultValue={overtimePercent}
              onChange={(value: number) => setOvertimePercent(value)}
            />
            <NumberField
              label="Overtidstimer"
              defaultValue={overtimeHours}
              onChange={(value: number) => setOvertimeHours(value)}
            />
            <NumberField
              label="Bonus"
              defaultValue={bonus}
              onChange={(value: number) => setBonus(value)}
              isLast={true}
            />
          </div>
          <div className="sm:flex sm:flex-col sm:flex-1 my-12 sm:my-0">
            <BigNumber
              label="Lønn"
              amount={totalSalary}
              color="text-orange-400"
            />
            <BigNumber
              label="Pensjon"
              amount={totalPension}
              color="text-orange-300"
            />
            <BigNumber
              label="Totalt"
              amount={totalIncome}
              color="text-orange-500"
              isLast={true}
            />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
